/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import React from 'react'
import { get, isFunction, reduce } from 'lodash'
import { graphql } from 'gatsby'
import { DateTime } from 'luxon'

import Introduction from '../components/introduction'
import { FullHeader } from '../components/page-header'
import PageWrapper from '../components/page-wrapper'
import Link from '../components/link'
import Card from '../components/card'

import { Space, VStack, FlexCol, FlexCols, HStack } from '../styles/els'
import { useThemeUISetValue } from '../styles/utils'

import { formatImage, LinkTypes } from '../lib/helpers'
import Section from '../components/section'
import CardType from '../components/card-type'

const getPressLink = (press) => {
  return press.linkTo === 'file' && press.file?.mediaItemUrl
    ? press.file.mediaItemUrl
    : press.url
}

const getPressDate = (date) => {
  return date
    ? DateTime.fromFormat(date, 'LL/yyyy').toFormat('LLLL yyyy')
    : null
}

const ProductionCard = (props) => {
  const { where, when, title, url, hasLink, ...otherProps } = props

  const date = getPressDate(when)

  return (
    <Card>
      <CardType sub={date} heading={`${title}, ${where}`} tier={2} />
    </Card>
  )
}

const ProductionLink = (props) => {
  const { where, when, title, url, hasLink, ...otherProps } = props

  const date = getPressDate(when)

  return (
    <HStack
      space={3}
      sx={{
        display: ['block', null, 'flex'],
        flexWrap: ['wrap', null, null, null, 'nowrap'],
        color: !hasLink ? 'grey1' : null,
      }}
    >
      {where ? <div>{where}</div> : null}
      {title ? (
        <div
          sx={{
            color: 'grey1',
          }}
        >
          {title}
        </div>
      ) : null}
      <div
        sx={{
          color: 'grey1',
          flex: ['none', null, null, null, 1],
          textAlign: ['left', null, null, null, 'right'],
          width: ['100%', null, null, null, 'auto'],
          position: 'relative',
          pr: 7,
        }}
      >
        {date ? <span>{date}</span> : null}
        {hasLink ? (
          <span
            sx={{
              position: ['relative', null, null, null, 'absolute'],
              right: 0,
              top: 0,
              pl: [3, null, null, null, 0],
            }}
          >
            &rarr;
          </span>
        ) : null}
      </div>
    </HStack>
  )
}

const ProductionReview = (props) => {
  const { title, parentTitle, reviews } = props

  return (
    <div>
      <FlexCols>
        <FlexCol across={[4, null, null, 1]} base={4}>
          {title ? <h3 sx={{ variant: 'text.heading_25' }}>{title}</h3> : null}
          {parentTitle ? (
            <h4 sx={{ variant: 'text.heading_25', color: 'grey1' }}>
              {parentTitle}
            </h4>
          ) : null}
        </FlexCol>
        <FlexCol across={[4, null, null, 3]} base={4}>
          <div
            sx={{
              pt: [0, null, null, '5px'],
              mt: ['-12px', 0],
            }}
          >
            {reviews && reviews.length > 0
              ? reviews.map((_review, _j) => {
                  const _link = getPressLink(_review)
                  return _link ? (
                    <Link
                      to={_link}
                      type={LinkTypes.EXTERNAL}
                      key={_j}
                      sx={{
                        display: 'block',
                      }}
                    >
                      <ProductionLink {..._review} hasLink={true} />
                    </Link>
                  ) : (
                    <ProductionLink {..._review} key={_j} />
                  )
                })
              : null}
          </div>
        </FlexCol>
      </FlexCols>
    </div>
  )
}

const PressPage = (props) => {
  const { data } = props

  const { theme } = useThemeUI()
  const pT = useThemeUISetValue('headingPadding', theme)

  const title = get(data, 'page.title')
  const headerImage = formatImage(get(data, 'page.acfPage.headerImage'))
  const articles = get(data, 'page.acfTemplatePress.reviewsPress')
  const productions = get(data, 'productions.nodes')

  return (
    <PageWrapper>
      <FullHeader image={headerImage} />
      <Space space={pT} />
      {title ? <Introduction title={title} /> : null}
      {articles && articles.length > 0 ? (
        <Section>
          <h3 sx={{ variant: 'text.heading_36', pb: 6 }}>
            Articles & podcasts
          </h3>
          <FlexCols>
            {articles.map((_article, _i) => {
              const _link = getPressLink(_article)

              return (
                <FlexCol base={[1, null, 2, 3]} key={_i}>
                  {_link ? (
                    <Link
                      to={_link}
                      type={LinkTypes.EXTERNAL}
                      sx={{
                        display: 'block',
                      }}
                    >
                      <ProductionCard {..._article} hasLink={true} />
                    </Link>
                  ) : (
                    <ProductionCard {..._article} />
                  )}
                </FlexCol>
              )
            })}
          </FlexCols>
        </Section>
      ) : null}
      {productions && productions.length > 0 ? (
        <Section>
          <h3 sx={{ variant: 'text.heading_36', pb: 6 }}>
            Production reviews & features
          </h3>
          <VStack>
            {productions.map((_production, _i) => {
              const _reviews = get(_production, 'acfProduction.reviewsPress')
              const _otherReviews = reduce(
                get(_production, 'acfProduction.otherProductions'),
                (_r, _v, _k) => {
                  return _v.reviewsPress && _v.reviewsPress.length > 0
                    ? [
                        ..._r,
                        {
                          title: _v.title,
                          reviews: _v.reviewsPress,
                        },
                      ]
                    : _r
                },
                []
              )

              return (
                <React.Fragment key={_i}>
                  {_reviews && _reviews.length > 0 ? (
                    <ProductionReview
                      title={_production.title}
                      reviews={_reviews}
                    />
                  ) : null}
                  {_otherReviews && _otherReviews.length > 0
                    ? _otherReviews.map((_r, _j) => {
                        return (
                          <ProductionReview
                            {..._r}
                            key={_j}
                            parentTitle={_production.title}
                          />
                        )
                      })
                    : null}
                </React.Fragment>
              )
            })}
          </VStack>
        </Section>
      ) : null}
    </PageWrapper>
  )
}

export default PressPage

export const query = graphql`
  query ($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      template {
        templateName
      }
      acfPage {
        pageColour
        headerImage {
          ...FullsizeImage
        }
      }
      acfTemplatePress {
        reviewsPress {
          title
          when
          where
          linkTo
          url
          file {
            mediaItemUrl
          }
        }
      }
    }
    productions: allWpProduction(
      sort: {
        order: [DESC, ASC]
        fields: [acfProduction___dateStart, menuOrder]
      }
    ) {
      nodes {
        title
        acfProduction {
          reviewsPress {
            title
            when
            where
            linkTo
            url
            file {
              mediaItemUrl
            }
          }
          otherProductions {
            title
            reviewsPress {
              title
              when
              where
              linkTo
              url
              file {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  }
`
